import { nextAPI } from "@api/axiosAPI";
import { signIn, signOut } from "next-auth/react";
import {
  getDataFromLocal,
  removeAllDataFromLocal,
  saveDataToLocal,
} from "./localStorage";
import Constants from "../utilities/Constants";
import * as Sentry from "@sentry/nextjs";
import CacheHelper from "./cacheHelper";

const REDIRECT_URL = `${process.env.NEXTAUTH_URL}company`;
const LOGOUT_REDIRECT = `${process.env.NEXT_PUBLIC_SSOURL}/logout?post_logout_redirect_uri=${process.env.NEXTAUTH_URL}company`;
const LOGOUT_URL = `${process.env.NEXTAUTH_URL}api/auth/federated-logout`;
const LOGOUT_SALESFORCE = `${process.env.SALESFORCE_OAUTH_URL}/logout?d=${process.env.NEXTAUTH_URL}`;
const SDM = process.env.IS_SDM === "true" ? true : false;


const callLogin = async () => {
  const provider = SDM ? "salesforce" : "keycloak"
  signIn(provider, { callbackUrl: REDIRECT_URL });
};

const callLogout = async (session, saveLoginProcessCompleted, router) => {
  const { provider = null, token = null, accessToken: salesforceToken, id_token = null } = session || {};

  if (SDM || (provider === "salesforce" && salesforceToken)) {
    const confirmData = getDataFromLocal("confirmData");
    removeAllDataFromLocal();
    saveDataToLocal("confirmData", confirmData);

    try {
      await nextAPI.post("/auth/federated-logout", { token: salesforceToken });
      await signOut();
    } catch(error) {
      console.error(error);
    }

    window.open(LOGOUT_SALESFORCE, "_self");
  } else {
    try {

      const logoutUrl = new URL(process.env.NEXT_PUBLIC_SSOURL + '/logout');

      const postLogoutUrl = new URL(process.env.NEXTAUTH_URL);
      if (id_token) {
        logoutUrl.searchParams.append('id_token_hint', id_token);
      }

      logoutUrl.searchParams.append('post_logout_redirect_uri',postLogoutUrl.href);

      await signOut({
        redirect: false,
      });

      const confirmData = getDataFromLocal("confirmData");

      removeAllDataFromLocal();
      saveDataToLocal("confirmData", confirmData);

      router.push(logoutUrl.href);
    } catch(e) {
      console.error(e)
    }
  }
  saveLoginProcessCompleted(false);
};

const checkCustomerWithIssues = (customerToCheck) => {
  if(customerToCheck === null) return false

  let customerToCheck2 = customerToCheck

  if(typeof customerToCheck === 'string') {
    try {
      customerToCheck2 = JSON.parse(customerToCheck)
    }
    catch(e) {
      return false
    } 
  }

  if(!customerToCheck2
    || !customerToCheck2.id
    || !customerToCheck2.customer_type
    ) {
    return false
  }

  return true
}

const cleanLocalStorageAndRestoreConfirmDataAndSaveCustomerIssue = (msg = undefined) => {
  cleanLocalStorageAndRestoreConfirmData(msg)
  saveDataToLocal(Constants.LOCAL_STORAGE_CUSTOMER_ISSUE, Constants.LOCAL_STORAGE_CUSTOMER_ISSUE);
}

const cleanLocalStorageAndRestoreConfirmData = (msg = undefined) => {
  const confirmData = getDataFromLocal("confirmData");
  removeAllDataFromLocal();
  saveDataToLocal("confirmData", confirmData);
  if(msg) {
    Sentry.captureMessage(msg);
  }
}

const checkAndCleanCustomerWithIssues = (customerToCheck) => {
  const checkCustomerWithIssuesResult = checkCustomerWithIssues(customerToCheck)
  if(!checkCustomerWithIssuesResult) {
    cleanLocalStorageAndRestoreConfirmDataAndSaveCustomerIssue("Customer malformed! " + customerToCheck)
    return false
  }

  return true
}

const checkCachedTokenOrGetNewOne = async () => {

  const authCredentials = {
    clientSecret: process.env.KEYCLOAK_CLIENT_SECRET,
    clientId: process.env.KEYCLOAK_CLIENT_ID,
    realm: process.env.KEYCLOAK_REALM,
    username: process.env.KEYCLOAK_USERNAME,
    password: process.env.KEYCLOAK_PASSWORD,
  };

  const login = async () => {
    const loginRes = await nextAPI.post(process.env.NEXT_PUBLIC_APIURL + "/api/auth/login", authCredentials);
    CacheHelper.set(`accessData`, loginRes.data, (loginRes.data.expires_in * 1000) - 60);
    return loginRes.data;
  };
  const checkToken = async (accessData) => {
    const { status } = await nextAPI.get(process.env.NEXT_PUBLIC_APIURL + "/api/auth/is_token_valid", {
      headers: {
        Authorization: `Bearer ${accessData.access_token}`,
      },
    })
    if (status !== 200) {
      throw new Error("Token is not valid");
    }
    return accessData;
  };

  let authDataResponse = null;
  const cachedAuthData = CacheHelper.get(`accessData`);

  try {
    if (!cachedAuthData) {
      authDataResponse = await login();
    } else {
      authDataResponse = await checkToken(cachedAuthData);
    }
  } catch (error) {
    authDataResponse = await login();
  }

  return authDataResponse.access_token;
};

 const deleteLocalStorageAndLogoutCustomerNotMatch = async ( router, error, addToast, session, actionSaveLoginProcessCompleted ) => { 
  if (error === "customer-not-found") {
    addToast();
    await callLogout(session, actionSaveLoginProcessCompleted, router);
  }
}

export { 
  callLogin,
  callLogout, 
  checkAndCleanCustomerWithIssues, 
  checkCustomerWithIssues, 
  cleanLocalStorageAndRestoreConfirmData,
  checkCachedTokenOrGetNewOne,
  LOGOUT_URL,
  cleanLocalStorageAndRestoreConfirmDataAndSaveCustomerIssue,
  deleteLocalStorageAndLogoutCustomerNotMatch 
};
